import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ReplayIcon from "@mui/icons-material/Replay";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FiEdit } from "react-icons/fi";
import CancelIcon from "@mui/icons-material/Cancel";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import "../deviceModel/viewDeviceModel.css";
import UserRole from "../pageHelpers/userRole";
import CsvUpload from "./csvUpload";
import DeviceDataModal from "./viewDeviceData";
import ReactPaginate from "react-paginate";

export default function ViewOnboardDevice() {
  const { statusCode, loading, apiRequest } = useAxiosApiRequest();
  const tenantId = JSON.parse(localStorage.getItem("TenantID"));
  const { isAdmin } = UserRole();
  const [searchValue, setSearchValue] = useState("");
  const [devices, setDevices] = useState([]);
  const [selectedDeviceModal, setSelectedDeviceModal] = useState(null);
  const [deviceTypeData, setdeviceTypeData] = useState([]);
  const [modelNames, setmodelNames] = useState([]);
  const [sortField, setSortField] = useState("null");
  const [sortOrder, setSortOrder] = useState("desc");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);
  const [searchFields, setSearchFields] = useState({
    device_type: "",
    model_name: "",
  });
  const { device_type, model_name } = searchFields;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getOnboarddevices(1, null, null, "null", "desc", null);
    getDeviceTypeData();
  }, []);

  const navigate = useNavigate();

  const handleOpenModal = (device) => {
    setSelectedDeviceModal(device);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleUploadFile = () => {
    setOpenFileUploadDialog(true);
  };

  const handleCloseFileUploadDialog = () => {
    setOpenFileUploadDialog(false);
  };

  const handleCreateOnClick = () => {
    navigate("/viewDevice/onboardDevice");
  };

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleUpdateOnClick = (serialNumber, deviceStatus) => {
    if (deviceStatus !== "INACTIVE") {
      navigate(`/viewDevice/Updatedevice/${serialNumber}`);
    }
  };

  const handleSearch = (e) => {
    getOnboarddevices(1, null, null, "null", "desc", searchValue);
  };

  const handleResetFilters = () => {
    setSearchFields({
      device_type: "",
      model_name: "",
    });
    setPageNumber(1);
    getOnboarddevices(1, null, null, "null", "desc", null);
  };

  const handleResetSearch = () => {
    setSearchValue("");
    getOnboarddevices(1, null, null, "null", "desc", null);
  };

  const handleSort = (field) => {
    let newOrder = "asc";
    if (sortField === field && sortOrder === "asc") {
      newOrder = "desc";
    }
    setSortField(field);
    setSortOrder(newOrder);

    let filterType = model_name
      ? "deviceModel.modelName"
      : device_type
        ? "deviceType.name"
        : "null";
    let filterValue = model_name
      ? model_name
      : device_type
        ? device_type
        : null;

    getOnboarddevices(
      pageNumber,
      filterType,
      filterValue,
      field,
      newOrder,
      null,
    );
  };
  const handleRecordsPerPage = (e) => {
    const records = Number(e.target.value);
    setRecordsPerPage(records);
    setPageNumber(1);
    let filterType = model_name
      ? "deviceModel.modelName"
      : device_type
        ? "deviceType.name"
        : "null";
    let filterValue = model_name
      ? model_name
      : device_type
        ? device_type
        : null;

    getOnboarddevices(
      1,
      filterType,
      filterValue,
      sortField,
      sortOrder,
      null,
      records,
    );
  };
  const handleDeviceTypeChange = (e) => {
    setSearchFields((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    getModelNames(`deviceType.name`, e.target.value, "null", "desc");
    getOnboarddevices(
      1,
      "deviceType.name",
      e.target.value,
      "null",
      "desc",
      "null",
    );
  };

  const handleModelNameChange = (e) => {
    setSearchFields((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const changePage = (selected) => {
    const page = selected?.selected;
    if (page !== undefined) {
      setPageNumber(page + 1);
      let filterType = model_name
        ? "deviceModel.modelName"
        : device_type
          ? "deviceType.name"
          : "null";
      let filterValue = model_name
        ? model_name
        : device_type
          ? device_type
          : null;
      getOnboarddevices(
        page + 1,
        filterType,
        filterValue,
        sortField,
        sortOrder,
        null,
      );
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSearchFields({
      device_type: "",
      model_name: "",
    });
    setOpen(false);
  };
  const handleApplyFilters = () => {
    setPageNumber(1);
    if (model_name) {
      getOnboarddevices(
        1,
        "deviceModel.modelName",
        model_name,
        "null",
        "desc",
        "null",
      );
    }
    setOpen(false);
  };

  const getDeviceTypeData = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/masterdata/deviceType`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenantId,
        },
      },
      body: {},
    });
    const Data = response?.deviceMasterData;
    if (Array.isArray(Data)) {
      setdeviceTypeData(Data);
    }
  };

  const getModelNames = async (filterField, fieldValue, field, newOrder) => {
    const response = await apiRequest({
      endpoint: `/deviceservice/devicemodel/allmodels/10/1/${filterField}/${fieldValue}/${field}/${newOrder}`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenantId,
        },
      },
      body: {},
    });
    const Data = response?.deviceModels;
    if (Array.isArray(Data)) {
      setmodelNames(Data);
    }
  };

  const getOnboarddevices = async (
    page,
    filtertype,
    filterTypeValue,
    sortField,
    newOrder,
    searchValue,
    devicesPerPage = recordsPerPage,
  ) => {
    const response = await apiRequest({
      endpoint: `/deviceservice/device/alldevices/${devicesPerPage}/${page}/${filtertype}/${filterTypeValue}/${searchValue}/${sortField}/${newOrder}`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenantId,
        },
      },
      body: {},
    });
    const Data = response?.devices;
    const totalPageCount = response?.totalPageCount;
    const totalDocumentCount = response?.totalDocumentCount;
    if (Array.isArray(Data)) {
      setDevices(Data);
      setTotalRecords(totalPageCount);

      setDataLength(totalDocumentCount);
    }
  };

  return (
    <Handler statusCode={statusCode} loading={loading}>
      <div style={{ paddingLeft: "35px" }}>
        <CsvUpload
          open={openFileUploadDialog}
          handleClose={handleCloseFileUploadDialog}
          reloaDeviceData={getOnboarddevices}
        />
        <DeviceDataModal
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          device={selectedDeviceModal}
          reloaDeviceData={getOnboarddevices}
        />
        <div className="heading">
          <h4>Onboarded Device</h4>
          {isAdmin && (
            <div className="deviceButtons">
              <button
                type="submit"
                className="btn btn-dark"
                style={{
                  minWidth: "20%",
                  marginBottom: "16px",
                  padding: "12px 20px",
                }}
                onClick={handleCreateOnClick}
              >
                Onboard Device
              </button>
              <button
                type="submit"
                className="btn btn-dark"
                style={{
                  minWidth: "20%",
                  marginBottom: "16px",
                  padding: "12px 20px",
                }}
                onClick={handleUploadFile}
              >
                Onboard Bulk Device
              </button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between", // Use space-between to position elements on the left and right
            alignItems: "center",
            width: "100%",
            marginBottom: "16px",
          }}
        >
          <h6 style={{ margin: 0 }}>Total Records: {dataLength}</h6>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              maxWidth: "500px",
              marginLeft: "10px",
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Search data by VIN or MAC Address or IMEI or Serial Number..."
              value={searchValue}
              onChange={handleInputChange}
              style={{ flex: 1 }}
              InputProps={{
                endAdornment: searchValue && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleResetSearch} size="small">
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "52px",
                },
              }}
            />
            <IconButton
              onClick={handleSearch}
              style={{
                padding: "6px 10px",
                border: "1px solid #080808",
                backgroundColor: "#080808",
                color: "#ffffff",
                borderRadius: "0 5px 5px 0",
                height: "52px",
              }}
            >
              <SearchIcon />
            </IconButton>
            <button onClick={handleClickOpen} className="actionButton">
              <FilterAltOutlinedIcon titleAccess="Filter" />
            </button>
            <button className="actionButton" onClick={handleResetFilters}>
              <ReplayIcon titleAccess="Reset Filters" />
            </button>
          </div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="filter-dialog-title"
          >
            <DialogTitle id="filter-dialog-title">Filter Options</DialogTitle>
            <DialogContent>
              <div style={{ paddingRight: "20px", marginBottom: "10px" }}>
                <FormControl style={{ width: "250px" }} size="small">
                  <label style={{ fontWeight: "bold" }}>Device Type</label>
                  <select
                    className="form-control"
                    id="device_type"
                    value={device_type}
                    required
                    onChange={handleDeviceTypeChange}
                  >
                    <option disabled value={""}>
                      Select Device Type
                    </option>
                    {deviceTypeData &&
                      deviceTypeData.map((getDeviceTypes) => (
                        <option key={getDeviceTypes.id}>
                          {getDeviceTypes.name}
                        </option>
                      ))}
                  </select>
                </FormControl>
              </div>
              <div style={{ paddingRight: "20px", marginBottom: "10px" }}>
                <FormControl style={{ width: "250px" }} size="small">
                  <label style={{ fontWeight: "bold" }}>Device Model</label>
                  <select
                    className="form-control"
                    id="model_name"
                    value={model_name}
                    required
                    onChange={handleModelNameChange}
                  >
                    <option disabled value={""}>
                      Select Device Model
                    </option>
                    {modelNames.map((value, index) => (
                      <option key={index} value={value.modelName}>
                        {value.modelName}
                      </option>
                    ))}
                  </select>
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleApplyFilters} color="primary">
                Apply
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Paper sx={{ width: "100%", boxShadow: "none", overflow: "hidden" }}>
          <TableContainer>
            <Table>
              <TableHead
                className="table-head"
                sx={{
                  maxHeight: 20,
                  cursor: "pointer",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      // fontWeight: 700,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    align="center"
                    onClick={() => handleSort("deviceType.name")}
                  >
                    Device Type
                    {sortField === "deviceType.name" && sortOrder === "asc" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      // fontWeight: 700,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    align="center"
                    onClick={() => handleSort("deviceModel")}
                  >
                    Model Name
                    {sortField === "deviceModel" && sortOrder === "asc" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      // fontWeight: 700,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    align="center"
                    onClick={() => handleSort("deviceIdentifiers.serialNumber")}
                  >
                    Serial Number
                    {sortField === "deviceIdentifiers.serialNumber" &&
                    sortOrder === "asc" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      // fontWeight: 700,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    align="center"
                    onClick={() => handleSort("deviceIdentifiers.VIN")}
                  >
                    VIN
                    {sortField === "deviceIdentifiers.VIN" &&
                    sortOrder === "asc" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      // fontWeight: 700,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    align="center"
                    onClick={() => handleSort("currentFirmwareVersion")}
                  >
                    Firmware Version
                    {sortField === "currentFirmwareVersion" &&
                    sortOrder === "asc" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      // fontWeight: 700,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    align="center"
                    onClick={() => handleSort("deviceIdentifiers.imei")}
                  >
                    IMEI
                    {sortField === "deviceIdentifiers.imei" &&
                    sortOrder === "asc" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      // fontWeight: 700,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    align="center"
                    onClick={() => handleSort("deviceIdentifiers.macAddress")}
                  >
                    MAC
                    {sortField === "deviceIdentifiers.macAddress" &&
                    sortOrder === "asc" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </TableCell>
                  {isAdmin ? (
                    <TableCell sx={{ fontWeight: 700 }} align="center">
                      Action
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {devices &&
                  devices.map((device, index) => {
                    return (
                      <TableRow
                        style={
                          index % 2
                            ? { backgroundColor: "rgba(72, 34, 180, 0.04)" }
                            : { backgroundColor: "white" }
                        }
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={device.id}
                      >
                        <TableCell align="center">
                          {device.deviceType?.name || "--"}
                        </TableCell>
                        <TableCell align="center">
                          {device.deviceModel[0]?.modelName || "--"}
                        </TableCell>
                        <TableCell align="center">
                          {device.deviceType?.deviceIdType === "serialnumber"
                            ? device.deviceId
                            : device.deviceIdentifiers?.serialNumber || "-"}
                          {/* {device.deviceIdentifiers?.serialNumber} */}
                        </TableCell>
                        <TableCell align="center">
                          {/* {data.deviceID === 'vin' ? data.vin : device.deviceIdentifiers?.VIN} */}
                          {device.deviceType?.deviceIdType === "vin"
                            ? device.deviceId
                            : device.deviceIdentifiers?.VIN || "-"}
                          {/* {device.deviceIdentifiers?.VIN} */}
                        </TableCell>
                        <TableCell align="center">
                          {device.currentFirmwareVersion}
                        </TableCell>
                        <TableCell align="center">
                          {device.deviceType?.deviceIdType === "imei"
                            ? device.deviceId
                            : device.deviceIdentifiers?.imei || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {device.deviceType?.deviceIdType === "macaddress"
                            ? device.deviceId
                            : device.deviceIdentifiers?.macAddress || "-"}
                        </TableCell>
                        {isAdmin ? (
                          <TableCell
                            sx={{
                              display: "block",
                            }}
                          >
                            <FiEdit
                              style={{
                                marginRight: "10px",
                                fontSize: "110%",
                                color:
                                  device.deviceStatus === "INACTIVE"
                                    ? "grey"
                                    : "#4822B4",
                              }}
                              title={"Update"}
                              onClick={() =>
                                handleUpdateOnClick(
                                  device?.deviceId,
                                  device?.deviceStatus,
                                )
                              }
                            />
                            <VisibilityIcon
                              onClick={() => handleOpenModal(device)}
                              style={{
                                fontSize: "120%",
                                color: "#4822B4",
                                cursor: "pointer",
                              }}
                              title={"View"}
                            />
                          </TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <br />
        <div className="pagenate">
          <h6>
            <strong>Total Records : {dataLength}</strong>
          </h6>
          <div>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
              <InputLabel id="recordsPerPage-label">Show</InputLabel>
              <Select
                labelId="recordsPerPage-label"
                id="recordsPerPage"
                value={recordsPerPage}
                onChange={(e) => {
                  handleRecordsPerPage(e);
                }}
                label="Show"
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </FormControl>
          </div>

          <span>
            <ReactPaginate
              previousLabel={<KeyboardArrowLeftIcon />}
              nextLabel={<KeyboardArrowRightIcon />}
              breakLabel={"...."}
              marginPagesDisplayed={2}
              pageCount={totalRecords}
              forcePage={pageNumber - 1}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </span>
        </div>
      </div>
    </Handler>
  );
}
