import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../App";
import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import SuccessCreateModal from "../../modals/successModalCreate";
import "./onboardDevice.css";
import Breadcrumb from "../../breadCrumbs/navigation";

export default function DeviceOnboard() {
  const { statusCode, loading, errormsg, apiRequest } = useAxiosApiRequest();
  const { userName } = useContext(AuthContext);
  const tenantId = JSON.parse(localStorage.getItem("TenantID"));

  const [mandatoryField, setMandatoryField] = useState("");
  const [mandatoryFieldValue, setMandatoryFieldValue] = useState("");

  const [deviceTypeData, setDeviceTypeData] = useState([]);
  const [selectedDeviceTypeData, setSelectedDeviceTypeData] = useState({});
  const [modelName, setModelName] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [selectedModelName, setSelectedModelName] = useState("");
  const [selectedModelNameID, setSelectedModelNameID] = useState([]);
  const [openDialog, setOpenDialog] = useState(false); //success dialog
  const [formData, updateFormData] = useState({
    deviceType: "",
    model_name: "",
    serial_number: "",
    manufacturer: "",
    vin: "",
    original_firmware_version: "",
    current_firmware_version: "",
    thing_name: "",
    imei: "",
    mac: "",
    hardware_version: "",
    tenantId: tenantId,
    year: "",
  });

  useEffect(() => {
    if (selectedModelName) {
      getModelName();
    }
  }, [selectedModelName]);

  useEffect(() => {
    GetDeviceType();
  }, []);

  const [selectedYear, setSelectedYear] = useState("");

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= 1900; year--) {
    years.push(year);
  }

  const {
    deviceType,
    model_name,
    serial_number,
    manufacturer,
    vin,
    original_firmware_version,
    current_firmware_version,
    imei,
    mac,
    hardware_version,
  } = formData;

  useEffect(() => {
    if (mandatoryField === "serialnumber" && serial_number !== "") {
      setMandatoryFieldValue(serial_number);
    } else if (mandatoryField === "vin" && vin !== "") {
      setMandatoryFieldValue(vin);
    } else if (mandatoryField === "imei" && imei !== "") {
      setMandatoryFieldValue(imei);
    } else if (mandatoryField === "macaddress" && mac !== "") {
      setMandatoryFieldValue(mac);
    }
  }, [serial_number, vin, mac, imei]);
  const devicedata = {
    tenantId: tenantId,
    deviceType: selectedDeviceTypeData,
    deviceId: mandatoryFieldValue,
    deviceModel: selectedModelNameID,
    manufacturerName: manufacturer,
    originalFirmwareVersion: original_firmware_version,
    currentFirmwareVersion: current_firmware_version,
    year: selectedYear,
    hardwareVersion: hardware_version,
    deviceStatus: "REGISTERED",
    thingName: null,
    createdBy: userName,
    updatedBy: userName,
    deviceIdentifiers: {
      // serialNumber: serial_number,
    },
  };

  const [validationErrors, setValidationErrors] = useState({
    deviceType: "",
    model_name: "",
    serial_number: "",
    manufacturer: "",
    vin: "",
    original_firmware_version: "",
    current_firmware_version: "",
    thing_name: "",
    imei: "",
    mac: "",
    hardware_version: "",
  });

  const handleChange = (e) => {
    setSelectedYear(e.target.value);
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const onHandleChange = (e) => {
    const { value } = e.target;
    setFieldErrors({
      ...fieldErrors,
      [e.currentTarget.id]: "",
    });
    if (/^[a-zA-Z0-9.]*$/.test(value)) {
      updateFormData({
        ...formData,
        [e.target.id]: e.target.value,
      });
      setValidationErrors({
        ...validationErrors,
        [e.target.id]: "",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        [e.target.id]: "Special characters are not allowed.",
      });
    }
  };

  const onHandleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onHandleDeviceTypeChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    const selectedDeviceType = e.target.value;
    setSelectedModelName(selectedDeviceType);
    const selectedDeviceTypeDescription = deviceTypeData.find(
      (desc) => desc.name === selectedDeviceType,
    );
    if (selectedDeviceTypeDescription) {
      const description = selectedDeviceTypeDescription?.description;
      const deviceIDType = selectedDeviceTypeDescription?.deviceIdType;
      const deviceData = {
        name: selectedDeviceType,
        description: description,
        deviceIdType: deviceIDType,
      };
      setMandatoryField(deviceIDType);
      setSelectedDeviceTypeData(deviceData);
    }
    setFieldErrors({
      ...fieldErrors,
      [e.currentTarget.id]: "",
    });
  };

  const onHandleModelNameChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    const selectedModelName = e.target.value;
    const selectedModelNameId = modelName.find(
      (desc) => desc.modelName === selectedModelName,
    );
    if (selectedModelNameId) {
      const modelname = {
        modelName: selectedModelNameId.modelName,
        modelId: selectedModelNameId?._id,
      };
      setSelectedModelNameID(modelname);
    }
    setFieldErrors({
      ...fieldErrors,
      [e.currentTarget.id]: "",
    });
  };

  const GetDeviceType = async () => {
    const deviceTypeData = await apiRequest({
      endpoint: `/deviceservice/masterdata/deviceType`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenantId,
        },
      },
      body: {},
    });
    const Data = deviceTypeData?.deviceMasterData;
    if (Array.isArray(Data)) {
      setDeviceTypeData(Data);
    }
  };

  const getModelName = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/devicemodel/allmodels/60/1/deviceType.name/${selectedModelName}/null/desc`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenantId,
        },
      },
      body: {},
    });
    const Data = response?.deviceModels;
    if (Array.isArray(Data)) {
      setModelName(Data);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.deviceType.trim()) {
      newErrors.deviceType = "Select Device Type";
    }
    if (!formData.model_name.trim()) {
      newErrors.model_name = "Select Model Number";
    }
    if (mandatoryField === "serialnumber" && !formData.serial_number.trim()) {
      newErrors.serial_number = "Enter Serial Number";
    }
    if (mandatoryField === "vin" && !formData.vin.trim()) {
      newErrors.vin = "Enter Device VIN";
    }
    if (mandatoryField === "imei" && !formData.imei.trim()) {
      newErrors.imei = "Enter Device IMEI";
    }
    if (mandatoryField === "macaddress" && !formData.mac.trim()) {
      newErrors.mac = "Enter Device MAC";
    }
    setFieldErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const deviceData = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/device`,
      method: "POST",
      additionalParams: {
        headers: {
          "tenant-id": tenantId,
        },
      },
      body: devicedata,
    });
    if (response && statusCode === 200) {
      setOpenDialog(true);
    }
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    if (mandatoryField !== "serialnumber" && serial_number !== "") {
      devicedata.deviceIdentifiers.serialNumber = serial_number;
    }

    if (mandatoryField !== "vin" && vin !== "") {
      devicedata.deviceIdentifiers.VIN = vin;
    }

    if (mandatoryField !== "imei" && imei !== "") {
      devicedata.deviceIdentifiers.imei = imei;
    }

    if (mandatoryField !== "macaddress" && mac !== "") {
      devicedata.deviceIdentifiers.macAddress = mac;
    }
    if (validateForm()) {
      deviceData();
      updateFormData({
        deviceType: "",
        model_name: "",
        serial_number: "",
        manufacturer: "",
        vin: "",
        original_firmware_version: "",
        current_firmware_version: "",
        thing_name: "",
        imei: "",
        mac: "",
        hardware_version: "",
      });
      setSelectedYear("");
    }
  };

  return (
    <div>
      <Handler statusCode={statusCode} loading={loading} errormsg={errormsg} />

      {openDialog && (
        <SuccessCreateModal
          openDialog={openDialog}
          onHandleClose={onHandleCloseDialog}
        />
      )}
      <Breadcrumb />
      <div className="deviceForm">
        <h4>Add New Device</h4>
        <form className="mt-3 mb-3" onSubmit={(e) => onHandleSubmit(e)}>
          <div className="form-group row">
            <div className="form-group col-md-6 mb-2">
              <label id="mandatorylabels">Select Device Type</label>
              <select
                className="form-control"
                id="deviceType"
                value={deviceType}
                onChange={onHandleDeviceTypeChange}
              >
                <option selected disabled value={""}>
                  Select Device Type
                </option>
                {deviceTypeData &&
                  deviceTypeData.map((getDeviceName) => (
                    <option key={getDeviceName.id}>{getDeviceName.name}</option>
                  ))}
              </select>
              {fieldErrors && fieldErrors.deviceType && (
                <p className="error-message">{fieldErrors.deviceType}</p>
              )}
            </div>
            <div className="form-group col-md-6 mb-2">
              <label id="mandatorylabels">Model Name</label>
              <select
                className="form-control"
                id="model_name"
                value={model_name}
                onChange={onHandleModelNameChange}
              >
                <option value={""}>Select Model Name</option>
                {modelName &&
                  modelName.map((getModelName) => (
                    <option key={getModelName.id}>
                      {getModelName.modelName}
                    </option>
                  ))}
              </select>
              {fieldErrors && fieldErrors.model_name && (
                <p className="error-message">{fieldErrors.model_name}</p>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="form-group col-md-6 mb-2">
              <label
                id={mandatoryField === "serialnumber" ? "mandatorylabels" : ""}
              >
                Serial Number
              </label>
              <input
                type="string"
                className="form-control"
                id="serial_number"
                value={serial_number}
                placeholder="Enter Serial Number"
                maxLength={50}
                onChange={onHandleChange}
              />
              {validationErrors && validationErrors.serial_number && (
                <p className="error-message">
                  {validationErrors.serial_number}
                </p>
              )}
              {fieldErrors && fieldErrors.serial_number && (
                <p className="error-message">{fieldErrors.serial_number}</p>
              )}
            </div>
            <div className="form-group col-md-6 mb-2">
              <label id={mandatoryField === "vin" ? "mandatorylabels" : ""}>
                VIN
              </label>
              <input
                type="string"
                className="form-control"
                id="vin"
                value={vin}
                placeholder="Enter vin Number"
                maxLength={50}
                onChange={onHandleChange}
              />
              {validationErrors.vin && (
                <p className="error-message">{validationErrors.vin}</p>
              )}
              {fieldErrors && fieldErrors.vin && (
                <p className="error-message">{fieldErrors.vin}</p>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="form-group col-md-6 mb-2">
              <label id={mandatoryField === "imei" ? "mandatorylabels" : ""}>
                Device IMEI
              </label>
              <input
                type="string"
                className="form-control"
                id="imei"
                value={imei}
                placeholder="Enter Device IMEI"
                maxLength={50}
                onChange={onHandleChange}
              />
              {validationErrors.imei && (
                <p className="error-message">{validationErrors.imei}</p>
              )}
              {fieldErrors && fieldErrors.imei && (
                <p className="error-message">{fieldErrors.imei}</p>
              )}
            </div>
            <div className="form-group col-md-6 mb-2">
              <label
                id={mandatoryField === "macaddress" ? "mandatorylabels" : ""}
              >
                Device MAC
              </label>
              <input
                type="string"
                className="form-control"
                id="mac"
                value={mac}
                placeholder="Enter Device MAC"
                maxLength={100}
                onChange={onHandleChange}
              />
              {validationErrors.mac && (
                <p className="error-message">{validationErrors.mac}</p>
              )}
              {fieldErrors && fieldErrors.mac && (
                <p className="error-message">{fieldErrors.mac}</p>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="form-group col-md-6 mb-2">
              <label>Manufacturer Name</label>
              <input
                type="string"
                className="form-control"
                id="manufacturer"
                value={manufacturer}
                placeholder="Enter Manufacturer Name"
                maxLength={50}
                onChange={onHandleChange}
              />
              {validationErrors.manufacturer && (
                <p className="error-message">{validationErrors.manufacturer}</p>
              )}
            </div>
            <div className="form-group col-md-6 mb-2">
              <label>Year</label>
              <select
                className="form-control"
                id="year"
                value={selectedYear}
                onChange={handleChange}
              >
                <option selected disabled value="">
                  Select Year
                </option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group row">
            <div className="form-group col-md-6 mb-2">
              <label>Original Firmware Version</label>
              <input
                type="string"
                className="form-control"
                id="original_firmware_version"
                value={original_firmware_version}
                placeholder="Enter Original Firmware Version"
                maxLength={15}
                onChange={onHandleChange}
              />
              {validationErrors.original_firmware_version && (
                <p className="error-message">
                  {validationErrors.original_firmware_version}
                </p>
              )}
            </div>
            <div className="form-group col-md-6 mb-2">
              <label>Current Firmware Version</label>
              <input
                type="string"
                className="form-control"
                id="current_firmware_version"
                value={current_firmware_version}
                placeholder="Enter Current Firmware Version"
                maxLength={15}
                onChange={onHandleChange}
              />
              {validationErrors.current_firmware_version && (
                <p className="error-message">
                  {validationErrors.current_firmware_version}
                </p>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="form-group col-md-6 mb-2">
              <label>Device Hardware Version</label>
              <input
                type="string"
                className="form-control"
                id="hardware_version"
                value={hardware_version}
                placeholder="Enter Hardware Version"
                maxLength={15}
                onChange={onHandleChange}
              />
              {validationErrors.hardware_version && (
                <p className="error-message">
                  {validationErrors.hardware_version}
                </p>
              )}
            </div>
          </div>
          <br />
          <div className="form-group row">
            <div className="col-sm-12">
              <button type="submit" className="btn btn-dark btn-block">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
