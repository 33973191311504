import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../App";
import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import SuccessModal from "../../modals/successModal";
import "./onboardDevice.css";
import Breadcrumb from "../../breadCrumbs/navigation";

export default function DeviceOnboard() {
  const { statusCode, loading, apiRequest } = useAxiosApiRequest();
  const { userName } = useContext(AuthContext);
  const tenantId = JSON.parse(localStorage.getItem("TenantID"));
  const navigate = useNavigate();

  const [deviceTypeData, setDeviceTypeData] = useState([]);
  const [selectedDeviceTypeData, setSelectedDeviceTypeData] = useState({});
  const [modelName, setModelName] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedModelNameID, setSelectedModelNameID] = useState([]);
  const [openDialog, setOpenDialog] = useState(false); //success dialog
  const [formData, updateFormData] = useState({
    deviceType: "",
    model_name: "",
    manufacturerName: "",
    originalFirmwareVersion: "",
    currentFirmwareVersion: "",
    hardwareVersion: "",
    tenantId: tenantId,
  });

  useEffect(() => {
    if (formData.deviceType.length) {
      getModelName();
    }
  }, [formData.deviceType]);

  useEffect(() => {
    getDeviceType();
    deviceData();
  }, []);

  const { serialNumber } = useParams();
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= 1900; year--) {
    years.push(year);
  }

  const updatedDeviceData = {
    deviceType: selectedDeviceTypeData,
    deviceModel: [selectedModelNameID],
    manufacturerName: formData.manufacturerName,
    originalFirmwareVersion: formData.originalFirmwareVersion,
    currentFirmwareVersion: formData.currentFirmwareVersion,
    year: selectedYear,
    hardwareVersion: formData.hardwareVersion,
    deviceStatus: "REGISTERED",
    thingName: null,
    updatedBy: userName,
  };

  const onHandleCloseDialog = () => {
    setOpenDialog(false);
    navigate("/viewDevice");
  };

  const onHandleDeviceTypeChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    const selectedDeviceType = e.target.value;
    const selectedDeviceTypeDescription = deviceTypeData.find(
      (desc) => desc.name === selectedDeviceType,
    );
    if (selectedDeviceTypeDescription) {
      const description = selectedDeviceTypeDescription?.description;
      // const deviceIDType = selectedDeviceTypeDescription?.deviceIdType;
      const deviceData = {
        name: selectedDeviceType,
        description: description,
        // deviceIdType: deviceIDType,
      };
      setSelectedDeviceTypeData(deviceData);
    }
  };

  const onHandleModelNameChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    const selectedModelName = e.target.value;
    const selectedModelNameId = modelName.find(
      (desc) => desc.modelName === selectedModelName,
    );
    if (selectedModelNameId) {
      const modelname = {
        modelName: selectedModelNameId.modelName,
        modelId: selectedModelNameId?._id,
      };
      setSelectedModelNameID(modelname);
    }
  };

  const handleChange = (e) => {
    setSelectedYear(e.target.value);
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const onHandleChange = (e) => {
    const { value } = e.target;
    if (/^[a-zA-Z0-9.]*$/.test(value)) {
      updateFormData({
        ...formData,
        [e.target.id]: e.target.value,
      });
      setValidationErrors({
        ...validationErrors,
        [e.target.id]: "",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        [e.target.id]: "Special characters are not allowed.",
      });
    }
  };

  const [validationErrors, setValidationErrors] = useState({
    deviceType: "",
    model_name: "",
    serial_number: "",
    manufacturerName: "",
    vin: "",
    originalFirmwareVersion: "",
    currentFirmwareVersion: "",
    imei: "",
    mac: "",
    hardwareVersion: "",
  });

  const getDeviceType = async () => {
    const deviceTypeData = await apiRequest({
      endpoint: `/deviceservice/masterdata/deviceType`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenantId,
        },
      },
      body: {},
    });
    const Data = deviceTypeData?.deviceMasterData;
    if (Array.isArray(Data)) {
      setDeviceTypeData(Data);
    }
  };

  const getModelName = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/devicemodel/allmodels/60/1/deviceType.name/${formData.deviceType}/null/desc`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenantId,
        },
      },
      body: {},
    });
    const Data = response?.deviceModels;
    if (Array.isArray(Data)) {
      setModelName(Data);
    }
  };

  const deviceData = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/device/deviceId/${serialNumber}`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenantId,
        },
      },
      body: {},
    });
    const Data = response;
    const devicetypedata = response?.deviceType;
    if (Data) {
      updateFormData({
        deviceType: Data?.deviceType?.name,
        model_name: Data?.deviceModel[0]?.modelName,
        manufacturerName: Data?.manufacturerName,
        originalFirmwareVersion: Data?.originalFirmwareVersion,
        currentFirmwareVersion: Data?.currentFirmwareVersion,
        hardwareVersion: Data?.hardwareVersion,
      });
      const date = Data?.year?.split("-");
      setSelectedYear(date[0]);
      setSelectedDeviceTypeData(devicetypedata);
      setSelectedModelNameID(Data?.deviceModel[0]);
    }
  };

  const addUpdatedDevices = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/device/deviceId/${serialNumber}`,
      method: "PUT",
      additionalParams: {
        headers: {
          "tenant-id": tenantId,
        },
      },
      body: updatedDeviceData,
    });
    if (response && statusCode === 200) {
      setOpenDialog(true);
    }
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    addUpdatedDevices();
  };

  return (
    <div>
      <Handler statusCode={statusCode} loading={loading} />
      {openDialog && (
        <SuccessModal
          openDialog={openDialog}
          onHandleClose={onHandleCloseDialog}
        />
      )}
      <Breadcrumb />
      <div className="deviceForm">
        <h4>Update device</h4>
        <form className="mt-3 mb-3" onSubmit={onHandleSubmit}>
          <div className="form-group row">
            <div className="form-group col-md-6 mb-2">
              <label htmlFor="deviceType" id="mandatorylabels">
                Select Device Type
              </label>
              <select
                className="form-control"
                id="deviceType"
                value={formData.deviceType}
                onChange={onHandleDeviceTypeChange}
              >
                <option value="" disabled>
                  Select Device Type
                </option>
                {deviceTypeData &&
                  deviceTypeData.map((getDeviceName) => (
                    <option key={getDeviceName.id}>{getDeviceName.name}</option>
                  ))}
              </select>
              {validationErrors.deviceType && (
                <p className="error-message">{validationErrors.deviceType}</p>
              )}
            </div>
            <div className="form-group col-md-6 mb-2">
              <label htmlFor="model_name" id="mandatorylabels">
                Model Name
              </label>
              <select
                className="form-control"
                id="model_name"
                value={formData.model_name}
                onChange={onHandleModelNameChange}
              >
                <option value="" disabled>
                  Select Model Name
                </option>
                {modelName &&
                  modelName.map((getModelName) => (
                    <option key={getModelName.id}>
                      {getModelName.modelName}
                    </option>
                  ))}
              </select>
              {validationErrors.model_name && (
                <p className="error-message">{validationErrors.model_name}</p>
              )}
            </div>
          </div>

          <div className="form-group row">
            <div className="form-group col-md-6 mb-2">
              <label htmlFor="year">Year</label>
              <select
                className="form-control"
                id="year"
                value={selectedYear}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select Year
                </option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-md-6 mb-2">
              <label htmlFor="manufacturerName">Manufacturer Name</label>
              <input
                type="text"
                className="form-control"
                id="manufacturerName"
                value={formData.manufacturerName}
                placeholder="Enter Manufacturer Name"
                maxLength={50}
                onChange={onHandleChange}
              />
              {validationErrors.manufacturerName && (
                <p className="error-message">
                  {validationErrors.manufacturerName}
                </p>
              )}
            </div>
          </div>

          <div className="form-group row">
            <div className="form-group col-md-6 mb-2">
              <label htmlFor="originalFirmwareVersion">
                Original Firmware Version
              </label>
              <input
                type="text"
                className="form-control"
                id="originalFirmwareVersion"
                value={formData.originalFirmwareVersion}
                placeholder="Enter Original Firmware Version"
                maxLength={15}
                onChange={onHandleChange}
              />
              {validationErrors.originalFirmwareVersion && (
                <p className="error-message">
                  {validationErrors.originalFirmwareVersion}
                </p>
              )}
            </div>
            <div className="form-group col-md-6 mb-2">
              <label htmlFor="currentFirmwareVersion">
                Current Firmware Version
              </label>
              <input
                type="text"
                className="form-control"
                id="currentFirmwareVersion"
                value={formData.currentFirmwareVersion}
                placeholder="Enter Current Firmware Version"
                maxLength={15}
                onChange={onHandleChange}
              />
              {validationErrors.currentFirmwareVersion && (
                <p className="error-message">
                  {validationErrors.currentFirmwareVersion}
                </p>
              )}
            </div>
          </div>

          <div className="form-group row">
            <div className="form-group col-md-6 mb-2">
              <label htmlFor="hardwareVersion">Device Hardware Version</label>
              <input
                type="text"
                className="form-control"
                id="hardwareVersion"
                value={formData.hardwareVersion}
                placeholder="Enter Hardware Version"
                maxLength={15}
                onChange={onHandleChange}
              />
              {validationErrors.hardwareVersion && (
                <p className="error-message">
                  {validationErrors.hardwareVersion}
                </p>
              )}
            </div>
          </div>

          <div className="form-group row">
            <div className="col-sm-12">
              <button type="submit" className="btn btn-dark btn-block">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
