import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { FiEdit } from "react-icons/fi";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Handler from "../../components/handler";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ReplayIcon from "@mui/icons-material/Replay";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import DeviceModelDetailsModal from "./deviceModelStatus";
import "./viewDeviceModel.css";
import "../firmware/ListFirmware.css";
export default function ViewdeviceModel() {
  const userRole = useSelector((state) => state.tokens?.tokens?.userRole);
  const finalRoles = userRole ? userRole.split("/") : null;
  const CURRENT_USER_TYPE = finalRoles ? finalRoles[1] : null;

  const tenantID = localStorage.getItem("TenantID");
  const tenant = tenantID ? JSON.parse(tenantID) : null;
  const adminrole = "adminrole";

  //to handle data received
  const [deviceModels, setdeviceModels] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDeviceModal, setSelectedDeviceModal] = useState(null);
  const [sortField, setSortField] = useState("null");
  const [sortOrder, setSortOrder] = useState("desc");
  const [deviceTypeData, setDeviceTypeData] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [formData, setFormData] = useState({
    deviceType: "",
  });

  const navigate = useNavigate();

  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const { statusCode, loading, apiRequest } = useAxiosApiRequest();
  const { deviceType } = formData;
  const [open, setOpen] = useState(false);
  useEffect(() => {
    getDeviceType();
    getdevice(recordsPerPage, pageNumber, "null", "null", "null", "desc");
  }, []);

  const handleOpenModal = (deviceModel) => {
    setSelectedDeviceModal(deviceModel);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCreateOnClick = () => {
    navigate("/deviceModels/AddDeviceModel");
  };

  const handleUpdateOnClick = (modelStatus, modelNumber) => {
    if (modelStatus === "ACTIVE") {
      navigate(`/deviceModels/UpdatedeviceModel/${modelNumber}`);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeviceTypeChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const handleFilterReset = () => {
    setFormData((prevState) => ({
      ...prevState,
      deviceType: "",
    }));
  };
  const handleApplyFilters = () => {
    setPageNumber(1);
    getdevice(
      recordsPerPage,
      pageNumber,
      `deviceType.name`,
      deviceType,
      "null",
      "desc",
    );
    setOpen(false);
  };
  const changePage = (selected) => {
    const page = selected?.selected;
    if (page !== undefined) {
      setPageNumber(page);
      getdevice(recordsPerPage, page, null, null, sortField, sortOrder);
    }
  };

  const handleSort = (field) => {
    let newOrder = "asc";
    if (sortField === field && sortOrder === "asc") {
      newOrder = "desc";
    }
    setSortField(field);
    setSortOrder(newOrder);
    getdevice(recordsPerPage, pageNumber, "null", "null", field, newOrder);
  };
  const handleRecordsPerPage = (e) => {
    const records = Number(e.target.value);
    setRecordsPerPage(records);
    setPageNumber(0);
    getdevice(records, pageNumber, "null", "null", "null", "desc");
  };
  const getdevice = async (
    recordsPerPage,
    pages,
    filterField,
    fieldValue,
    field,
    newOrder,
  ) => {
    const response = await apiRequest({
      endpoint: `/deviceservice/devicemodel/allmodels/${recordsPerPage}/${pages + 1}/${filterField}/${fieldValue}/${field}/${newOrder}`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    });
    const Data = response?.deviceModels;
    const totalPageCount = response?.totalPageCount;
    const totalRecords = response?.totalDocumentCount;
    if (Array.isArray(Data)) {
      setdeviceModels(Data);
      setTotalRecords(totalRecords);
      setDataLength(totalPageCount);
    }
  };

  const getDeviceType = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/masterdata/deviceType`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    });
    const Data = response?.deviceMasterData;
    if (Array.isArray(Data)) {
      setDeviceTypeData(Data);
    }
  };

  return (
    <Handler statusCode={statusCode} loading={loading}>
      <div>
        <DeviceModelDetailsModal
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          DeviceModel={selectedDeviceModal}
          reloaDeviceData={getdevice}
        />
        <div className="heading">
          <h5>List of Available Device Models</h5>
          <FormControl size="small">
            {CURRENT_USER_TYPE &&
              CURRENT_USER_TYPE.localeCompare(adminrole, undefined, {
                sensitivity: "base",
              }) === 0 && (
                <div>
                  <button
                    type="submit"
                    className="btn btn-dark"
                    style={{
                      minWidth: "20%",
                      marginBottom: "16px",
                      padding: "12px 20px",
                    }}
                    onClick={handleCreateOnClick}
                  >
                    Create Device Model
                  </button>
                </div>
              )}
          </FormControl>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "16px",
          }}
        >
          <h6 style={{ margin: 0 }}>Total Records: {totalRecords}</h6>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              maxWidth: "500px",
              marginLeft: "10px",
            }}
          >
            <div>
              <div>
                <button onClick={handleClickOpen} className="actionButton">
                  <FilterAltOutlinedIcon titleAccess="Filter" />
                </button>

                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="filter-dialog-title"
                >
                  <DialogTitle id="filter-dialog-title">
                    Filter Options
                  </DialogTitle>
                  <DialogContent>
                    <div style={{ paddingRight: "20px", marginBottom: "10px" }}>
                      <FormControl style={{ width: "250px" }} size="small">
                        <select
                          style={{
                            float: "right",
                            padding: "5px 10px",
                            border: "2px solid #080808",
                          }}
                          className="form-control"
                          id="deviceType"
                          value={deviceType}
                          onChange={(e) => {
                            handleDeviceTypeChange(e);
                          }}
                        >
                          <option disabled value="">
                            Filter By Device Type
                          </option>
                          {deviceTypeData &&
                            deviceTypeData.map((getDeviceTypes) => (
                              <option key={getDeviceTypes.id}>
                                {getDeviceTypes.name}
                              </option>
                            ))}
                        </select>
                      </FormControl>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={handleApplyFilters} color="primary">
                      Apply
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
            <button className="actionButton" onClick={handleFilterReset}>
              <ReplayIcon titleAccess="reset filter" />
            </button>
          </div>
        </div>
        <br></br>
        <Paper sx={{ width: "100%", boxShadow: "none", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead
                className="table-head"
                sx={{ maxHeight: 20, cursor: "pointer" }}
              >
                <TableRow>
                  <TableCell
                    align="center"
                    onClick={() => handleSort("deviceType")}
                  >
                    Device Type
                    {sortField === "deviceType" && sortOrder === "asc" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </TableCell>
                  <TableCell align="center">DeviceID Type</TableCell>
                  <TableCell
                    align="center"
                    onClick={() => handleSort("modelName")}
                  >
                    Model Name
                    {sortField === "modelName" && sortOrder === "asc" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => handleSort("modelNumber")}
                  >
                    Model Number
                    {sortField === "modelNumber" && sortOrder === "asc" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </TableCell>
                  <TableCell align="center">Description</TableCell>
                  {CURRENT_USER_TYPE &&
                    CURRENT_USER_TYPE.localeCompare(adminrole, undefined, {
                      sensitivity: "base",
                    }) === 0 && (
                      <>
                        <TableCell align="center">Action</TableCell>
                      </>
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {deviceModels &&
                  deviceModels.map((deviceModel, index) => {
                    return (
                      <TableRow
                        className="tableRowShade"
                        tabIndex={-1}
                        key={deviceModel.id}
                      >
                        <TableCell align="center">
                          {deviceModel.deviceType?.name || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {deviceModel.deviceType?.deviceIdType || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {deviceModel.modelName || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {deviceModel.modelNumber || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {deviceModel.description || "-"}
                        </TableCell>
                        {CURRENT_USER_TYPE &&
                          CURRENT_USER_TYPE.localeCompare(
                            adminrole,
                            undefined,
                            {
                              sensitivity: "base",
                            },
                          ) === 0 && (
                            <>
                              <TableCell
                                sx={{
                                  display: "block",
                                }}
                              >
                                <FiEdit
                                  style={{
                                    marginRight: "10px",
                                    fontSize: "110%",
                                    color:
                                      deviceModel.modelStatus === "ACTIVE"
                                        ? "#1442cc"
                                        : "grey",
                                  }}
                                  title={"Update"}
                                  onClick={() =>
                                    handleUpdateOnClick(
                                      deviceModel.modelStatus,
                                      deviceModel.modelNumber,
                                    )
                                  }
                                />
                                <VisibilityIcon
                                  style={{
                                    fontSize: "120%",
                                    color: "#1442cc",
                                  }}
                                  title={"View"}
                                  onClick={() => handleOpenModal(deviceModel)}
                                />
                              </TableCell>
                            </>
                          )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <br></br>
          <div className="pagenate">
            <h6>
              <strong>Total Records: {totalRecords}</strong>
            </h6>
            <div>
              <FormControl
                variant="outlined"
                size="small"
                sx={{ minWidth: 120 }}
              >
                <InputLabel id="recordsPerPage-label">Show</InputLabel>
                <Select
                  labelId="recordsPerPage-label"
                  id="recordsPerPage"
                  value={recordsPerPage}
                  onChange={(e) => {
                    handleRecordsPerPage(e);
                  }}
                  label="Show"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                </Select>
              </FormControl>
            </div>

            <span>
              <ReactPaginate
                previousLabel={<KeyboardArrowLeftIcon />}
                nextLabel={<KeyboardArrowRightIcon />}
                breakLabel={"...."}
                marginPagesDisplayed={2}
                pageCount={dataLength}
                forcePage={pageNumber}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </span>
          </div>
        </Paper>
      </div>
    </Handler>
  );
}
