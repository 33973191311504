import { Paper, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import fileUploadIcon from "../../assets/lottie/fileUploadIon.json";
import loadingIcon from "../../assets/lottie/loadingIcon.json";
import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import FileUploadSuccessModal from "../../modals/fcsvFileUploadSuccess";
import "./fileUpload.css";

export default function CsvUpload({ open, handleClose, reloaDeviceData }) {
  const { statusCode, loading, apiRequest } = useAxiosApiRequest();
  const tenantId = JSON.parse(localStorage.getItem("TenantID"));

  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); //success dialog
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState({});
  const [responsemsg, setResponsemsg] = useState("");
  const [formData, updateFormData] = useState({
    model_image: null,
  });

  useEffect(() => {
    setIsFormValid(isSuccess);
  }, [isSuccess]);

  const onHandleCloseDialog = () => {
    setOpenDialog(false);
    reloaDeviceData(1, null, null, "null", "desc", null);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: fileUploadIcon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: loadingIcon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const dialogStyle = {
    width: "500px",
    height: "500px",
    padding: "20px",
  };
  const handleCloseButton = () => {
    setErrorMsg("");
    handleClose();
  };
  const handleFileChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    const image = e.target.files[0];
    const fileName = e.target.files[0].name;
    setSelectedFile(image);
    if (image) {
      const selectedFileSize = e.target.files[0].size;
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        const baseImage = reader.result.split(",")[1];
        const base64Size =
          baseImage.length * (3 / 4) - (baseImage.match(/=/g) || []).length;
        validateSelectedFile(selectedFileSize, fileName, baseImage, base64Size);
      };
    }
  };

  const validateSelectedFile = (fileSize, fileName, baseImage, base64Size) => {
    // const MIN_FILE_SIZE = 10 // 1MB
    const MAX_FILE_SIZE = 5 * 1024 * 1024; //5mb in bytes
    const allowedFormats = ["csv"];
    const fileSizeKiloBytes = fileSize / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg("File size is greater than maximum limit");
      setIsSuccess(false);
      return;
    } else if (base64Size > MAX_FILE_SIZE) {
      setErrorMsg("File size is greater than maximum limit");
      setIsSuccess(false);
      return;
    }

    const fileExtension = fileName.split(".").pop().toLowerCase();
    if (!allowedFormats.includes(fileExtension)) {
      setErrorMsg("File format is not supported");
      setIsSuccess(false);
      return;
    } else {
      setIsSuccess("File size and format is validated");
      setErrorMsg(true);
      const imageData = {
        fileName: fileName,
        base64: baseImage,
      };
      setFileData(imageData);
    }
  };

  const handleFileUpload = async () => {
    if (selectedFile) {
      const response = await apiRequest({
        endpoint: `/deviceservice/device/upload`,
        method: "POST",
        additionalParams: {
          headers: {
            "tenant-id": tenantId,
            "Content-Type": "application/json",
          },
        },
        body: fileData,
      });
      handleClose();
      setIsSuccess(false);
      setSelectedFile(null);
      if (response) {
        setResponsemsg(response?.message);
        setOpenDialog(true);
        // handleClose();
      }
    } else {
      setErrorMsg("Please Select File To Upload!!");
    }
  };
  return (
    <Handler statusCode={statusCode} loading={loading}>
      <div>
        {openDialog && (
          <FileUploadSuccessModal
            openDialog={openDialog}
            onHandleClose={onHandleCloseDialog}
            message={responsemsg}
          />
        )}
        <Dialog
          open={open}
          close={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <Paper style={dialogStyle}>
            <Typography
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Upload CSV File
            </Typography>
            <div className="file-upload-container">
              <div className="file-upload">
                <input
                  id="upload-input"
                  type="file"
                  value={formData.model_image}
                  onChange={handleFileChange}
                />
                <label htmlFor="upload-input" className="upload-label">
                  <div>
                    <Lottie options={defaultOptions} height={80} width={120} />
                  </div>
                  <div className="upload-text">
                    <p style={{ fontWeight: "bold" }}>
                      {" "}
                      {selectedFile ? selectedFile.name : "Browse File"}
                    </p>
                  </div>
                  {errorMsg && <p className="error-message">{errorMsg}</p>}
                  {isSuccess && <p className="success-message">{isSuccess}</p>}
                  <Typography>Allowed Formats : CSV</Typography>
                  <Typography>Maximum File Size : 5MB</Typography>
                </label>
              </div>
            </div>
            <DialogActions
              style={{ justifyContent: "center", display: "flex" }}
            >
              <button
                className="upload-button"
                onClick={handleFileUpload}
                disabled={!isFormValid}
              >
                {loading ? (
                  <Lottie options={defaultOptions2} height={25} width={80} />
                ) : (
                  "Upload"
                )}
              </button>
              <button
                className="upload-button"
                disabled={loading}
                onClick={handleCloseButton}
              >
                Close
              </button>
            </DialogActions>
          </Paper>
        </Dialog>
      </div>
    </Handler>
  );
}
